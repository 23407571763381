import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CartItem, CartResponse } from '../../models';
import { CommonModule } from '@angular/common';
import { DeliveryOptionsComponent } from '../delivery-option';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AttributePipe } from '@ea/products';
import { CartFacade } from '../../+state';
import { CartItemsByVendor, ShipMethod } from '../../models/cart.model';
import { FilterImagePipe } from 'src/app/modules/products/pipes';

@Component({
  selector: 'ea-select-delivery-options',
  standalone: true,
  imports: [
    CommonModule,
    DeliveryOptionsComponent,
    NgxSkeletonLoaderModule,
    AttributePipe,
    FilterImagePipe,
  ],
  templateUrl: './select-delivery-options.component.html',
  styleUrls: ['./select-delivery-options.component.scss'],
})
export class SelectDeliveryOptionsComponent implements OnChanges {
  @Input() cart: CartResponse | null = null;
  @Input() itemsByVendor?: CartItemsByVendor[];
  @Input() address: any = null;
  colorKey = 'colour';
  sizeKey = 'size';
  onSelectedOptionIsChanged(deliveryOption: any, vendor: CartItemsByVendor) {
    if (this.cart) {
      const shipMethod = {
        shipMethodId: deliveryOption.shippingMethodId,
        cost: {
          amount: deliveryOption.cost,
          currency: deliveryOption.currency,
          discount: 0,
        },
        shipmentCarrier: deliveryOption.carrier,
        shipmentMethod: deliveryOption.description,
        code :deliveryOption.code
      };

      const cartId = this.cart.cartId;
      this.setAddressToItem(cartId, shipMethod, vendor.items);
    }
  }

  setAddressToItem(cartId: string, shipMethod: ShipMethod, items: CartItem[]) {
    const address = {
      street1: this.address.addressLine1,
      street2: this.address.addressLine2,
      street3: this.address.addressLine3,
      zipCode: this.address.postalCode,
      city: this.address.city,
      country: this.address.country,
      phone: this.address.phone,
      state: this.address.county,
      email: this.address.email,
      name: {
        first: this.address.first,
        last: this.address.last,
      },
    };

    this.facade.setAddressToItem(cartId, address, shipMethod, items);
  }

  ngOnChanges(changes: SimpleChanges): void {

    const addressChange = changes['address'];
    if (addressChange?.currentValue) {  
      this.address = addressChange?.currentValue;
      this.setAddressToAllItems();
    }
  }

  async setAddressToAllItems() {
    if (this.cart && this.itemsByVendor) {
      const cartId = this.cart.cartId;
  
      for (let i = 0; i < this.itemsByVendor.length; i++) {
        const vendor = this.itemsByVendor[i];

          this.setAddressToItem(
            cartId,
            vendor.shipTo!.shipMethod,
            vendor.items
          );
        
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
    }
  }
  
  getItemTitle(item : CartItem){
    var title = '';
    if(item.attributes.filter(x => x.name == this.sizeKey).length > 0){
      title += `Size: ${item.attributes.filter(x => x.name == this.sizeKey)[0].value}<br>`
    }
    if(item.attributes.filter(x => x.name == this.colorKey).length > 0){
      title += `Colour: ${item.attributes.filter(x => x.name == this.colorKey)[0].value}\n`
    }
    if(title == ''){
      title = item.title;
    }
    return title;
  }


  constructor(private readonly facade: CartFacade) {
  }
}
